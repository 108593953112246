@import './form.css';
@import './header.css';

html {
  font-family: "Source Sans 3", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 16px;

  --tsn-green-rgb: 33, 168, 4;
  --tsn-green: rgb(var(--tsn-green-rgb));
  --tsn-green-dark-rgb: 22, 116, 3;
  --tsn-green-dark: rgb(var(--tsn-green-dark-rgb));
  --tsn-orange-rgb: 241, 126, 1;
  --tsn-orange: rgb(var(--tsn-orange-rgb));
  --tsn-orange-dark-rgb: 201, 108, 1;
  --tsn-orange-dark: rgb(var(--tsn-orange-dark-rgb));
  --error-color-rgb: 255, 64, 64;
  --error-color: rgb(var(--error-color-rgb));
  --form-control-invalid-color: rgb(248, 163, 163);

  --primary-color-0: #eaebec;
  --primary-color-10: #d6d7d8;
  --primary-color-20: #d6d7d8;
  --primary-color-30: #c1c2c5;
  --primary-color-40: #adaeb1;
  --primary-color-50: #989a9e;
  --primary-color-60: #83868b;
  --primary-color-70: #6f7277;
  --primary-color-80: #5a5d64;
  --primary-color-90: #464950;
  --primary-color-100: #31353d;
  --primary-color-110: #2c3037;
  --primary-color-120: #272a31;
  --primary-color-130: #22252b;
  --primary-color-140: #1d2025;
  --primary-color-150: #191b1f;
  --primary-color-160: #141518;
  --primary-color-170: #0f1012;
  --primary-color-180: #0a0b0c;
  --primary-color-190: #050506;

  --carousel-gradient-color-1: rgba(255, 255, 255, 0.8);
  --carousel-gradient-color-2: rgba(255, 255, 255, 0.7);
  --carousel-gradient-color-3: rgba(255, 255, 255, 0.0);

  --bg-color: var(--primary-color-20);
  --bg-color-2: var(--primary-color-40);
  --bg-color-3: var(--primary-color-50);
  --text-color: var(--primary-color-190);
  --text-color-2: var(--primary-color-140);

  --burger: url(./assets/burger-green.svg);

  background: var(--bg-color);
}

html[theme="dark"] {
  --carousel-gradient-color-1: rgba(0, 0, 0, 0.95);
  --carousel-gradient-color-2: rgba(0, 0, 0, 0.75);
  --carousel-gradient-color-3: rgba(0, 0, 0, 0.4);

  --bg-color: var(--primary-color-100);
  --bg-color-2: var(--primary-color-150);
  --bg-color-3: var(--primary-color-80);
  --text-color: var(--primary-color-0);
  --text-color-2: var(--primary-color-30);

  --burger: url(./assets/burger-green.svg);
}

@font-face {
  font-family: BankGothicMedium;
  src: url("./assets/Bank.ttf") format("truetype");
}

body {
  margin: 0;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root,
.App {
  display: flex;
  flex-grow: 1;
  height: 100%;
  max-width: 100%;
}

* {
  box-sizing: border-box;
}

.slide-text {
  font-size: 30px;
  font-weight: 300;
  line-height: 1.28;
}

main {
  flex-grow: 1;
  display: flex;
  overflow: auto;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: max(5vh, 100px);
  padding-left: max(10vw, 80px);
  padding-right: max(10vw, 80px);
  padding-bottom: 2em;

  flex-grow: 1;
  width: 100%;
  /* background: linear-gradient(150deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0.75) 65%, rgba(0, 0, 0, 0.4) 100%),
    url(./assets/business-bg.webp) center / cover no-repeat; */
  background: var(--bg-color);
}

.content {
  width: min(100%, 1280px);
  flex-grow: 1;
  padding: 2em;
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;

  >* {
    flex-grow: 1;
  }

  > :first-child {
    margin-right: 2em;
  }
}

.card {
  --glow-color: var(--bg-color-2);
  background: var(--bg-color-2);
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  margin-bottom: 2em;
  flex-grow: 1;
  width: 100%;
}

.card.small {
  padding: 1em;
  margin-bottom: 1em;
}

.card.small>.Title {
  --mark-size: 1em;
}

@media (width <=1000px) {
  .row {
    flex-direction: column;

    > :first-child {
      margin-right: 0;
    }
  }

  .main-content {
    padding: 1em;
    justify-content: center;
    align-items: center;
  }

  .slide-text {
    font-size: initial;
  }
}

.glow {
  box-shadow: 0px 0px 6px 1px var(--glow-color, currentColor);
}

.glow:hover {
  box-shadow: 0px 0px 6px 1px var(--glow-color-hover, var(--glow-color, currentColor));
}

.error {
  color: var(--error-color);
  width: 100%;
}

.contact-form {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5em;
  font-size: 1.25em;

  .span-2 {
    grid-column: 1 / span 2;
  }
}

.container-h {
  display: flex;
}

.container-v {
  display: flex;
  flex-direction: column;
}

@media (width <=1000px) {
  .container-h {
    flex-direction: column;
  }

  .contact-form {
    grid-template-columns: 1fr;

    .span-2 {
      grid-column: 1;
    }
  }
}

.hidden {
  /* height: 0;
  overflow: hidden; */
  display: none !important;
}

ul>li {
  list-style-type: square;

  &::marker {
    color: var(--tsn-orange);
  }
}