.Contact {
  form {
    margin-top: 2em;
  }

  .container-v {
    margin-right: 2em;
  }

  .leaflet-container {
    flex-grow: 1;
  }

  @media (width <=1000px) {

    .container-v {
      margin-right: 0;
      margin-bottom: 2em;
    }

    .leaflet-container {
      min-height: 200px;
    }
  }
}