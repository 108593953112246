label {
  display: inline-flex;
  flex-direction: column-reverse;
  justify-content: start;
}

label>[required]+span:after {
  display: inline;
  content: "*";
  margin-left: 0.5ex;
}

input:not([type="radio"], [type="checkbox"]),
textarea,
select {
  outline: none;
  padding: 1em 0.75em;
  border: 1px solid var(--text-color-2);
  color: var(--primary-color-190);
}

input:user-invalid,
textarea:user-invalid,
select:user-invalid {
  background: var(--form-control-invalid-color);
}

button {
  --bg: var(--bg-color-2);
  --bg-hover: var(--bg-color-3);

  border-radius: 0;
  border: none;
  color: var(--text-color);
  background: var(--bg);
  font-size: 1.25em;
  padding: 0.5em;
  cursor: pointer;

  background-position: center;
  transition: background 0.8s, color 0.8s;
}

button:hover {
  background: var(--bg-hover) radial-gradient(circle, transparent 1%, var(--bg-hover) 1%) center/15000%;
}

button:active {
  background-color: var(--bg);
  background-size: 100%;
  transition: background 0s, color 0s;
}

button.orange {
  --bg: var(--tsn-orange);
  --bg-hover: var(--tsn-orange-dark);
  --glow-color: var(--tsn-orange);
  --glow-color-hover: var(--tsn-orange-dark);
  color: var(--primary-color-0)
}

button.large {
  font-size: 2.5em;
}

@media (width <=1000px) {
  button.large {
    font-size: 1.75em;
  }
}

input[type="radio"] {
  background: transparent;
  display: inline-grid;
  place-content: center;

  -webkit-appearance: none;
  appearance: none;

  font: inherit;
  color: currentColor;
  width: 1.75em;
  height: 1.75em;
  border: 0.15em solid currentColor;
  cursor: pointer;
}

input[type="radio"]::before {
  content: "";
  width: 1.25em;
  height: 1.25em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1.25em 1.25em var(--text-color);
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  background-color: var(--bg-color);
  display: grid;
  place-content: center;
  height: 1.75em;
  border: 0.15em solid currentColor;
  border-radius: 0;
  transform: translateY(-0.075em);
  cursor: pointer;
}

input[type="checkbox"]:not(.toggle) {
  font: inherit;
  color: currentColor;
  width: 1.75em;
}

input[type="checkbox"]:not(.toggle)::before {
  content: "";
  width: 1.25em;
  height: 1.25em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background: url("./assets/check-mark.svg") center center / cover no-repeat;
}

input[type="checkbox"]:not(.toggle):checked::before {
  transform: scale(1);
}

input[type="checkbox"].toggle {
  width: 3em;
  position: relative;
}

input[type="checkbox"].toggle::before {
  content: "";
  transform: translateX(-0.65em);
  width: 1.25em;
  height: 1.25em;
  transition: 120ms transform ease-in-out;
  background: currentColor;
}

input[type="checkbox"].toggle:checked::before {
  transform: translateX(0.65em);
}