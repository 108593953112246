.ConfigItem {
  align-items: center;
  border: 1px solid var(--text-color);
  padding: 1em;
  cursor: pointer;
}

.config-item-header {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.config-item-name {
  margin-bottom: 1em;
}

.config-item-pricing {
  display: flex;

  >:first-child {
    margin-right: 1em;
  }
}

.config-item-body {
  display: flex;
  height: 100%;
  margin: 1em 0em;
}

.config-item-img {
  max-width: 10em;
  max-height: 10em;
  object-fit: contain;
  margin-right: 1em;
  background: white;
  padding: 0.25em;
}

.config-item-description:not(:empty) {
  margin-bottom: 1em;
}

.config-item-attrs {
  display: grid;
  grid-template-columns: minmax(25%, 70%) 1fr;
  flex-grow: 1;
  gap: 0em 0.5em;
  grid-auto-rows: max-content;
  max-height: 10em;
  overflow: auto;

  hr {
    grid-column: 1 / -1;
    color: var(--text-color);
    width: 100%;
  }
}

.config-item-attr-name,
.config-item-attr-value {
  white-space: wrap;
}

@media (width <=1000px) {
  .config-item-body {
    flex-direction: column;
    align-items: center;
  }

  .config-item-img {
    margin-right: 0em;
    margin-bottom: 1em;
  }

  .config-item-attrs {
    width: 100%;
    grid-template-columns: minmax(60%, 70%) 1fr;
    gap: 0em 0.5em;
  }

  .config-item-attr-name,
  .config-item-attr-value {
    word-break: break-all;
  }

  .config-item-pricing {
    flex-direction: column;
  
    >:first-child {
      margin-right: 0;
    }
  }
}