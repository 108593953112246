.App,
main {
  display: flex;
  flex-direction: column;
  color: var(--text-color);
}

header {
  --header-height: 6.5em;
  --logo-height: 4.5em;
  --burger-size: 3em;

  height: var(--header-height);
  background: var(--bg-color);
  display: grid;
  place-items: center center;
  grid-template-columns: 1fr 3fr 1fr;
  padding: 1em 2em;
  color: var(--text-color);

  font-weight: 600;
  text-transform: uppercase;
  z-index: 10000;
  box-shadow: 0px 0px 12px 0px var(--text-color);
}

.logo {
  grid-column: 1;
  justify-self: start;
}

nav.main-menu {
  grid-column: 2;
}

nav>a,
nav>a:after {
  margin: 0 1em;
  text-decoration: none;
  color: var(--text-color);
  position: relative;
  white-space: nowrap;
}

nav.tilemenu {
  display: grid;
  width: 100%;
  height: 100%;
  gap: 2em;
  grid-template-columns: repeat(2, calc(50% - 1em));
  grid-template-rows: 1fr 1fr;
}

nav.tilemenu>a {
  position: relative;
  margin: 0;
  border: 1px solid var(--text-color);
  padding: 2em;
  background: var(--tile-bg) center center / cover no-repeat;
}

nav.tilemenu>a:before {
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(150deg, rgba(var(--tsn-green-dark-rgb), 0.65) 0%, rgba(var(--tsn-green-dark-rgb), 0.35) 65%, rgba(var(--tsn-green-dark-rgb), 0.15) 100%) no-repeat border-box,
  linear-gradient(150deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.55) 65%, rgba(0, 0, 0, 0.35) 100%) no-repeat border-box;
  content: "";
  z-index: 1;
  opacity: 0.5;
  transition: opacity 0.2s ease;
}

nav.tilemenu>a:hover:before {
  opacity: 1;
}

nav.tilemenu>a:after {
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 4rem);
  height: calc(100% - 4rem);
  padding: 2rem;
  content: attr(data-text);
  z-index: 2;
  font-size: 2em;
  transition: font-size 0.2s ease;
  overflow: hidden;
  white-space: break-spaces;
}

nav.tilemenu>a:hover:after {
  font-size: 3em;
}

nav>a:first-child::after {
  left: 0;
}

nav.underline>a.active::after,
nav.underline>a:hover::after {
  position: absolute;
  display: block;
  content: '';
  width: 100%;
  border-bottom: 1px solid var(--text-color);
  bottom: -0.5em;
  left: -1em;
}

nav.submenu {
  margin-bottom: 1.5em;
  margin-top: 1em;
}

.lang> :first-child {
  border-right: 1px solid var(--text-color);
}

.burger {
  color: var(--text-color);
  background: var(--burger);
  width: var(--burger-size);
  height: var(--burger-size);
  grid-column: 3;
  justify-self: end;
}

.mobile {
  display: none !important;
}

@media (width <=1000px) {
  .mobile {
    display: initial !important;
  }

  .desktop {
    display: none !important;
  }

  nav.main-menu {
    position: absolute;
    top: var(--header-height);
    height: calc(100vh - var(--header-height));
    width: 100%;
    grid-column: 1 / 999;
    flex-direction: column;
    align-items: center;
    background: var(--bg-color);
    display: none;
    z-index: 100;
  }

  nav.tilemenu {
    grid-template-columns: 1fr;
    grid-template-rows: none;
    height: 100%;
  }

  nav.main-menu[data-open="true"] {
    display: flex;
  }

  nav.main-menu[data-open="true"]>* {
    padding-top: 1em;
    margin-bottom: 1em;
  }

  nav.main-menu[data-open="true"]>.lang {
    padding-top: 0.75em;
    margin-top: 1em;
  }
}

.Toastify {
  z-index: 100000;
}