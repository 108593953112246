.Title {
  display: flex;
  align-items: start;
  --color: var(--tsn-orange);
  --mark-size: 1.5em;
  --mark-half-size: calc(var(--mark-size) * sqrt(2) / 2);
  margin-bottom: calc(var(--mark-size) * 2 / 3);

  .mark {
    /* margin-top: var(--mark-half-size); */
    transform: rotate(45deg);
    border: 1px solid var(--color);
    background: var(--color);
    width: var(--mark-size);
    height: var(--mark-size);
    flex-shrink: 0;

    margin-top: calc(var(--mark-size) * sqrt(2) / 2 - 1em / 6);
  }

  .title-text {
    font-size: calc(var(--mark-size) * sqrt(2));
    margin: 0 calc(sqrt(2) * var(--mark-size) / 4 + 0.25em);
    flex-shrink: 0;
    white-space: wrap;
    max-width: calc(100% - var(--mark-size) * sqrt(2) - 1em);
  }

  .line-container {
    margin-top: calc(var(--mark-size) * sqrt(2) / 2 - 2px);
    flex-grow: 1;
    min-width: 1em;
  }

  hr {
    color: var(--color);
    padding: 0;
  }
}

@media (width <=1000px) {
  .Title {
    --mark-size: 1em;
  }
}