.Step {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.Step .body {
  flex-grow: 1;
  width: 100%;
}