.start-gallery.image-gallery,
.start-gallery .image-gallery-content,
.start-gallery .image-gallery-slide-wrapper,
.start-gallery .image-gallery-swipe,
.start-gallery .image-gallery-slides,
.start-gallery .image-gallery-slide {
  height: 100%;
  width: 100%;
}

.start-gallery.image-gallery {
  background: var(--bg-color);
}

.start-gallery .start-gallery-item {
  background: linear-gradient(150deg, var(--carousel-gradient-color-1) 0%, var(--carousel-gradient-color-2) 45%, var(--carousel-gradient-color-3) 100%),
    var(--img) center / cover no-repeat;
  line-height: initial;
  white-space: initial;
  text-align: start;
  justify-content: start;
  align-items: start;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.start-gallery .image-gallery-bullets .image-gallery-bullet {
  padding: 0px 17px;
  margin: 12px;
  border-width: 2px;
  border-radius: 0;
  border-color: var(--tsn-green);
  background: var(--tsn-green);
  box-shadow: none;
  position: relative;
}

.start-gallery .image-gallery-bullets .image-gallery-bullet:before {
  content: '';
  width: 38px;
  height: 24px;
  top: -12px;
  left: 0;
  display: block;
  position: absolute;
}

.start-gallery .image-gallery-bullets .image-gallery-bullet:hover {
  border-color: var(--tsn-green);
  background: var(--tsn-green);
  border-width: 2px;
}

.start-gallery .image-gallery-bullets .image-gallery-bullet.active {
  transform: none;
  border-width: 2px;
  border-color: var(--tsn-orange);
  background: var(--tsn-orange);
}

.start-gallery .image-gallery-bullets .image-gallery-bullet.active:hover {
  border-color: var(--tsn-orange);
  background: var(--tsn-orange);
}

.start-gallery .image-gallery-bullets {
  bottom: 150px;
}

.Start {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background: var(--bg-color);
  align-items: center;
}

.Start {
  width: 100%;
}

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: max(5vh, 100px);
  padding-left: max(10vw, 80px);
  padding-right: max(10vw, 80px);
  flex-grow: 0;
  flex-shrink: 0;
  background: linear-gradient(150deg, var(--carousel-gradient-color-1) 0%, var(--carousel-gradient-color-2) 45%, var(--carousel-gradient-color-3) 100%), url('../../assets/cctv-tilemenu.webp') center top / cover no-repeat;
  height: 700px;
}

.banner .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (width <=1000px) {
  .Start .banner {
    padding: 1em;
    height: 500px;
    background: linear-gradient(150deg, var(--carousel-gradient-color-1) 0%, var(--carousel-gradient-color-2) 5%, var(--carousel-gradient-color-3) 100%), url('../../assets/cctv-tilemenu.webp') center top / cover no-repeat;

    h3 {
      font-size: 2.5em;
      text-align: center;
    }
  }
}