.GenericConfigurator {
  .NumberField {
    align-self: stretch;
  }

  form {
    width: 100%;
  }
}

.stepper {
  padding: 0 1em 0 0;
  flex-shrink: 0;
}

.step-node {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .step-node-text {
    transform: rotate(-45deg);
    font-size: 16px;
    line-height: normal;
    user-select: none;
    -webkit-user-select: none;
  }
}

.stepper-step .label {
  user-select: none;
  -webkit-user-select: none;
}

.step-node.step-active {
  border: 4px solid var(--text-color);
}

[theme="light"] .step-node.step-active {
  border-width: 2px;
}

.step-node,
.stepper .stepConnector.rightConnector,
.stepper .middleStepConnector,
.stepper :has(.step-node)+div .stepConnector.leftConnector {
  background: var(--tsn-orange) !important;
}

.step-node.step-error,
.stepper :has(.step-error) .stepConnector.rightConnector,
.stepper :has(.step-error) .middleStepConnector,
.stepper :has(.step-error)+div .stepConnector.leftConnector {
  background: var(--error-color) !important;
}

.step-node.step-complete,
.stepper :has(.step-complete) .stepConnector.rightConnector,
.stepper :has(.step-complete) .middleStepConnector,
.stepper :has(.step-complete)+div .stepConnector.leftConnector {
  background: var(--tsn-green) !important;
}

.summary-section>.body {
  display: flex;
  flex-direction: column;

  button {
    flex-grow: 1;
  }
}